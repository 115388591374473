<template>
	<div>
		<v-card>
            <s-toolbar label="Reporte de pesado" dark color="#8e8f91" excel @excel="exportExcel()"></s-toolbar>
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="3" md="3">
                        <s-date label="Fecha Inicio" v-model="cDateInitial" @input="loadReportHeavy()"> </s-date>
                    </v-col>
                    <v-col cols="12" lg="3" md="3">
                        <s-date label="Fecha Fin" v-model="cDateFin" @input="loadReportHeavy()"> </s-date>
                    </v-col>
                </v-row>
                <v-row>
                    <v-container>
                        <s-toolbar label="Listado" dark color="info"></s-toolbar>
                        <v-card>
                            <v-data-table
                                item-key="WpbID"
                                dense
                                :items="itemsHeavy"
                                :headers="headersHeavy"
                                :items-per-page="-1"
                                hide-default-footer
                                group-by="RptNumberHarvestGuide"
                                disable-sort
                            > 
                            </v-data-table>
                        </v-card>
                    </v-container>
                </v-row>
            </v-container>
		</v-card>
	</div>
</template>
<script>
    import _sReceptionFresh from "../../../services/FreshProduction/ReceptionFreshService";
    import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";

	export default {
		components: {},
		data: () => ({

            itemsHeavy: [],
            headersHeavy: [
                { text: "N° Pallet", value: "RptNumberPalletsem", width: 80 },
                { text: "Semana", value: "RptWeek", width: 80 },
                { text: "Fecha", value: "RptDate", width: 80 },
                { text: "Guia Cosecha", value: "RptNumberHarvestGuide", width: 130 },
                { text: "Productor", value: "PrdCardName", width: 180 },
                { text: "Fundo/Lote", value: "FltName", width: 180 },
                { text: "Tipo Cultivo", value: "TypeCropName", width: 80 },
                { text: "Variedad", value: "VrtName", width: 80 },
                { text: "Peso Jaba", value: "WpbWeightJaba", width: 80 },
                { text: "Jabas", value: "WpbJabasQuantity", width: 80 },
                { text: "Peso Bruto", value: "WpbWeightGross", width: 80 },
                { text: "Peso Neto", value: "WpbWeightNet", width: 80 },
                { text: "Destare Jabas", value: "WpbDestareJaba", width: 80 },
                { text: "Promedio Jaba", value: "WpbWeightAverageJaba", width: 80 },
                

            ],
            cDateInitial: null, 
            cDateFin : null,
            report: {},
            processing:false,
        }),

        created(){
            this.cDateInitial = this.$fun.getDate();
            this.cDateFin = this.$fun.getDate();
            console.log(this.$fun.getDate());
            this.loadReportHeavy();
        },

		methods: {

            loadReportHeavy(){
                 _sReceptionFresh.GetReportHeavy({cDateInitial: this.cDateInitial, cDateFin: this.cDateFin, TypeDestiny: 2}, this.$fun.getUserID()).then((r) => {
                    if(r.status == 200){
                        this.itemsHeavy = r.data;
                        this.itemsHeavy.forEach(element => {
                            element.RptDate = this.$moment(element.RptDate).format(this.$const.FormatDateDB);
                        });
                    }
                    
                });
            },

            exportExcel(){
              /*  _sReceptionFresh.exportExcel(this.itemsHeavy, this.$fun.getUserID()).then((r) => {
                    if(r.status == 200){
                        this.$fun.downloadFile(r.data, this.$const.TypeFile.EXCEL, this.$fun.getUserID());
                    }
                    
                });*/


                 let parametervalue = [];

                parametervalue.push(this.cDateInitial)
                parametervalue.push(this.cDateFin)
                parametervalue.push(2)
                 
                let parametergen = " @cDateInitial, @cDateEnd, @TypeDestiny";
                this.downloadReport("REPORTE DE PESADO - RECEPCION","PrfReceptionFreshHeavy_R_ES",parametervalue,parametergen);


            },

            	downloadReport(QryNameReport,qryname,parametervalue,parametergen) {
				 console.log("QryNameReport",QryNameReport);

				this.report.QryNameReport = QryNameReport	;  //this.DateBegin + "," + this.DateEnd + "," + this.number;
				this.report.QryParamsArray = parametergen	;  //this.DateBegin + "," + this.DateEnd + "," + this.number;
				this.report.QryParamsArrayValue = parametervalue.join(","); //this.DateBegin + "," + this.DateEnd + "," + this.number;
				this.report.QryNameProcedure = qryname;
					//this.report.QryDatabaseNameName + ".." + this.report.QryNameSp;

				this.processing = true;
				_sQryConfigurationService
					.downloadexcel(this.report, this.$fun.getUserID())
					.then(
						(r) => {
							if (r.status == 200) {
								this.processing = false;

								this.$fun.downloadFile(
									r.data,
									this.$const.TypeFile.EXCEL,
									 QryNameReport
								);
							}
						},
						(e) => {
							//this.messageProcessing = e.response.data.Message;
							this.processing = true;
						}
					);

			 
			},
        },
	};
</script>
